//Help
const faq = () => import ('../views/FAQ.vue');
const Questions = () => import ('../views/Questions.vue');
const Contact = () => import ('../views/Contact.vue');
const Ticket = () => import ('../views/Ticket.vue');
const Tickets = () => import ('../views/Tickets.vue');

export default {
    requiresAuth: [
        {
            path: 'faq',
            name: 'Support:FAQ',
            meta: {requiresAuth: true},
            component: faq,
            children: [
                {
                    path: ':category',
                    name: 'Support:FAQCategory',
                    component: Questions
                }
            ]
        },
        {
            path: 'contact',
            name: 'Support:Contact',
            meta: {requiresAuth: true},
            component: Contact
        },
        {
            path: 'tickets',
            name: 'Support:Tickets',
            meta: {requiresAuth: true},
            component: Tickets
        },
        {
            path: 'ticket/:label',
            name: 'Support:Ticket',
            meta: {requiresAuth: true},
            component: Ticket
        },
    ]
};